.select2-container--default .select2-search--dropdown .select2-search__field {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.select2-container {
  .select2-selection--multiple, .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 42px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    outline: none;
    @include form-control;
  }
}

.select2-dropdown {
  border-color: color_lighten(primary, 27%) !important;
}

.select2-container {
  &.select2-container--open .select2-selection--multiple {
    @include form-control-focus;
  }
  &.select2-container--focus {
    .select2-selection--multiple, .select2-selection--single {
      @include form-control-focus;
    }
  }
  &.select2-container--open .select2-selection--single {
    @include form-control-focus;
  }
}

.select2-results__option {
  padding: 10px;
}

.select2-search--dropdown .select2-search__field {
  padding: 7px;
}

.select2-container--default {
  .select2-selection--single .select2-selection__rendered {
    min-height: 42px;
    line-height: 42px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .select2-selection--multiple .select2-selection__arrow, .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 40px;
    min-height: 42px;
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      @include shadow;
      color: #fff;
      padding-left: 10px;
      padding-right: 10px;
    }
    .select2-selection__rendered {
      padding-left: 10px;
      padding-right: 10px;
    }
    .select2-selection__choice__remove {
      margin-right: 5px;
      color: #fff;
    }
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: color(primary);
  color: #fff;
}

.select2-results__option {
  padding-right: 10px 15px;
}