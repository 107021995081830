.statistic-details {
  display: flex;
  flex-wrap: wrap;
  .statistic-details-item {
    flex: 1;
    padding: 17px 10px;
    text-align: center;
    .detail-chart {
      margin-bottom: 10px;
      padding: 0 20px;
    }
    .detail-name {
      font-size: 12px;
      margin-top: 5px;
      color: color(fontdark);
      letter-spacing: .3px;
    }
    .detail-value {
      font-size: 18px;
      font-weight: 700;
    }
  }
}

@include media-breakpoint-down(xs) {
  .statistic-details {
    flex-wrap: wrap;
    .statistic-details-item {
      flex: initial;
      width: 50%;
    }
  }
}