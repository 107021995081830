.profile-widget {
  margin-top: 35px;
  .profile-widget-picture {
    @include shadow;
    float: left;
    width: 100px;
    margin: -35px -5px 0 30px;
    position: relative;
    z-index: 1;
  }
  .profile-widget-header {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
  .profile-widget-items {
    display: flex;
    position: relative;
    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: -25px;
      right: 0;
      height: 1px;
      background-color: #f2f2f2;
    }
    .profile-widget-item {
      flex: 1;
      text-align: center;
      border-right: 1px solid #f2f2f2;
      padding: 10px 0;
      &:last-child {
        border-right: none;
      }
      .profile-widget-item-label {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: .5px;
        color: color(fontdark);
      }
      .profile-widget-item-value {
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .profile-widget-description {
    padding: 20px;
    line-height: 26px;
    .profile-widget-name {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
}

@include media-breakpoint-down(xs) {
  .profile-widget {
    .profile-widget-picture {
      left: 50%;
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      margin: 40px 0;
      float: none;
    }
    .profile-widget-items .profile-widget-item {
      border-top: 1px solid #f2f2f2;
    }
  }
}
