.icon-wrap {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 25px;
  width: calc(100% / 4);
  .icon {
    float: left;
    width: 40px;
    font-family: 'weathericons';
    font-size: 20px;
  }
  .icon-name {}
  .icon_unicode {
    width: 100%;
    padding-left: 45px;
    color: color(fontdark);
  }
}

.new-icons ul {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    padding: 10px;
  }
}

.icon-wrap .icon, .new-icons ul li .wi {
  font-size: 24px;
  margin-right: 15px;
  width: 30px;
  text-align: center;
}