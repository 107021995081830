.list-unstyled-border li {
  border-bottom: 1px solid #f9f9f9;
  padding-bottom: 15px;
  margin-bottom: 15px;
  .custom-checkbox {
    margin-right: 15px;
  }
  &:last-child {
  	margin-bottom: 0;
  	padding-bottom: 0;
    border-bottom: none;
  }
}

.list-unstyled-noborder li:last-child {
  border-bottom: none;
}

.list-group-item {
  &.active {
    background-color: color(primary);
  }
  &.disabled {
    color: color_darken(light, 8%);
  }
}

.list-group-item-primary {
  background-color: color(primary);
  color: #fff;
}

.list-group-item-secondary {
  background-color: color(secondary);
  color: #fff;
}

.list-group-item-success {
  background-color: color(success);
  color: #fff;
}

.list-group-item-danger {
  background-color: color(danger);
  color: #fff;
}

.list-group-item-warning {
  background-color: color(warning);
  color: #fff;
}

.list-group-item-info {
  background-color: color(info);
  color: #fff;
}

.list-group-item-light {
  background-color: color(light);
  color: color(dark);
}

.list-group-item-dark {
  background-color: color(dark);
  color: #fff;
}
