.pulsate {
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 1;
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}